<template>
  <div class="row mb-4 mt-4">
    <div
      class="col-12"
      v-for="card in cards"
      :key="card.id"
      :class="[card.type === 'ListType' ? 'col-md-12' : `col-md-${card.size}`]"
    >
      <TableType
        v-if="card.type === 'TableType'"
        :card="card"
        :activeBranch="activeBranch"
      />
      <ListType
        v-else-if="card.type === 'ListType'"
        :card="card"
      />
      <component
        :is="card.type"
        v-else
        :card="card"
        @getNext="getNext(card)"
        @getPrevention="getPrevention(card.preventions)"
        @isActive="activated = card.id"
        :activated="activated === card.id"
      />
    </div>
  </div>
</template>

<script>
import BoxType from '@/components/types/BoxType';
import CardType from '@/components/types/CardType';
import TableType from '@/components/types/TableType';
import ChartType from '@/components/types/ChartType';
import ListType from '@/components/types/ListType';
import InfoType from '@/components/types/InfoType';
import Api from '@/utils/services/Api';

export default {
  name: 'LevelRow',
  props: ['cards', 'level'],
  emits: ['next', 'prevention'],
  components: {
    BoxType,
    CardType,
    TableType,
    ChartType,
    ListType,
    InfoType,
  },
  data() {
    return {
      colSize: null,
      activated: null,
      activeBranch: null,
    };
  },
  methods: {
    getNext(card) {
      const concerns = ['access', 'price', 'reputation']
      if (concerns.includes(card.id)) this.activeBranch = card.id;

      const type = 'causes';
      this.$emit('next', type, card.id, this.level);
    },
    async getPrevention(options) {
      const type = 'prevention';
      const prevention = await Api.post(
        'preventions',
        { type: 'specific' },
        { options: options, branch: this.activeBranch }
      );
      this.$emit('prevention', type, prevention, this.level);
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  .col-12 {
    margin: 0 auto;
  }
}
</style>
