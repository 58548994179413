<template>
  <div class="row results-row">
    <SelectItem
      v-for="category in categories"
      :key="category.name"
      @selected="handleChange"
      :category="category"
      :activated="category.name === selected.category"
      placeholder="Filter by"
    />

    <div class="col-12 btn-right mt-2">
      <el-button type="default" @click="resetFilters" v-if="isFiltered"
        >Reset filters</el-button
      >

      <el-button
        type="info"
        @click="getFactSheet"
        v-if="selected.category === 'material'"
      >
        See factsheet
      </el-button>
    </div>
  </div>
  <div class="container" style="min-height: 15rem">
    <template v-if="showResults">
      <div class="limit-filter mt-2">
        <span class="text-italic">
          A material is critical if its score is among the highest
        </span>
        <el-select v-model="limit" @change="handleLimitChange">
          <el-option
            v-for="option in limitOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
        <span class="text-italic">of all available raw materials</span>
      </div>
      <LevelRow
        v-for="level in levels"
        :key="level.level_number"
        @next="getNextLevel"
        @prevention="getNextLevel"
        :cards="level.cards"
        :level="level.level_number"
      />
    </template>
  </div>
  <!-- Factsheet -->
  <el-dialog v-model="showModal">
    <div class="row mb-4">
      <div class="col-12 text-center">
        <h4 class="mb-2 text-primary">{{ factSheet.content }}</h4>
      </div>
    </div>
    <el-scrollbar max-height="500px" :always="true">
      <FactItem
        v-for="cause in factSheet.causes"
        :key="cause.id"
        :cause="cause"
      />
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="showModal = false">Close</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- End factsheet -->
</template>

<script>
import LevelRow from '@/components/specific/LevelRow';
import SelectItem from '@/components/utils/SelectItem';
import FactItem from '@/components/utils/FactItem';
import Api from '@/utils/services/Api';
import { ElLoading } from 'element-plus';


export default {
  name: 'ResultsRow',
  components: {
    LevelRow,
    FactItem,
    SelectItem,
  },
  data() {
    return {
      showModal: false,
      factSheet: {},
      showResults: false,
      data: [],
      levels: [],
      selected: {
        category: '',
        id: '',
      },
      categories: [],
      response: {},
      isFiltered: false,
      limit: 0.25,
      limitOptions: [
        {
          label: '25%',
          value: 0.25,
        },
        {
          label: '33%',
          value: 0.33,
        },
        {
          label: '50%',
          value: 0.5,
        },
        {
          label: '--',
          value: 0,
        },
      ],
    };
  },
  async created() {
    const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0)',
      });
    const res = await this.$store.dispatch('getResults');
    this.response = res;

    await this.initResults(res);
    loading.close()
  },
  methods: {
    async handleLimitChange() {
      if (this.isFiltered) return this.filterResults(this.selected.category, this.selected.id)
      await this.initResults(this.response);
    },
    async initResults(res) {
      this.categories = await Api.post('results', { type: 'categories' }, res);

      if (res.sectors.length) return this.getResults('sector', res.sectors);
      else if (!res.sectors.length && res.components.length)
        return this.getResults('component', res.components);
      else if (
        !res.sectors.length &&
        !res.components.length &&
        res.materials.length
      )
        return this.getResults('material', res.materials);
      else return;
    },
    async resetFilters() {
      this.isFiltered = false;
      this.selected = {
        category: '',
        id: '',
      };
      await this.initResults(this.response);
    },
    async getNextLevel(type, data, level) {
      let cards;

      if (type === 'prevention') cards = data;
      if (type === 'causes')
        cards = [
          {
            type: 'ListType',
            color: 'purple',
            branch: data,
            materials: this.data.concerns[data],
          },
        ];
      
      this.levels.length = level;

      await this.$nextTick();
      this.levels[level] = {
        level_number: level + 1,
        cards: cards,
      };
      this.levels.length = level + 1;
    },
    async handleChange(category, selected) {
      this.isFiltered = true;
      await this.filterResults(category, selected);
    },
    async getResults(category, selected) {
      this.activated = category;

      const { data, options } = await Api.post(
        'concerns',
        { type: 'init-results' },
        {
          category: category,
          selected: selected,
          limit: this.limit,
        }
      );

      await this.displayResults(data, options);
    },
    async filterResults(category, selected) {
      this.activated = category;

      this.selected = {
        category: category,
        id: selected,
      };

      const { data, options } = await Api.post(
        'concerns',
        { type: 'filter-results' },
        {
          category: category,
          selected: selected,
          limit: this.limit,
        }
      );

      await this.displayResults(data, options);
    },
    async displayResults(data, options) {
      this.data = data;
      this.showResults = false;

      await this.$nextTick();
      this.showResults = true;
      this.levels = [{ level_number: 1, cards: options }];
    },
    async getFactSheet() {
      this.factSheet = await Api.post(
        'materials',
        { type: 'factsheet' },
        {
          id: this.selected.id,
        }
      );

      this.showModal = true;
    },
  },
};
</script>

<style lang="scss">
.limit-filter {
  text-align: center;
  font-size: 18px;
  .el-select {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    max-width: 5rem;
  }
}

.results-row {
  height: auto;
  display: flex;
  align-items: center;
  padding: 1.5rem 0.5rem 0rem 0.5rem;
  margin: 0 auto;
  h3 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  .select-item {
    text-align: center;
  }
}
@include bp-down(lg) {
  .results-row {
    padding: 0.5rem;
    height: auto;
  }
}
</style>
