<template>
  <div class="btn-right mt-4 mb-4" v-if="materials.length > 5">
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="materials.length"
      :current-page="page"
      @current-change="setPage"
    ></el-pagination>
  </div>
  <ListItem
    v-for="material in paginated"
    :key="material.id"
    :branch="branch"
    :material="material"
    @isActive="activated = material.id"
    :activated="activated === material.id"
  />
  <div class="btn-right mt-4 mb-4" v-if="materials.length > 5">
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="materials.length"
      :current-page="page"
      @current-change="setPage"
    ></el-pagination>
  </div>
</template>

<script>
import ListItem from '@/components/utils/ListItem';

export default {
  name: 'ListType',
  props: ['card'],
  components: {
    ListItem,
  },
  data() {
    return {
      materials: [],
      activated: null,
      pageSize: 5,
      page: 1,
      branch: '',
    };
  },
  computed: {
    paginated() {
      return this.materials.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
  },
  created() {
    this.materials = this.card.materials;
    this.branch = this.card.branch;
  },
  methods: {
    setPage(val) {
      this.page = val;
    },
  },
};
</script>

<style lang="scss">
.el-pagination.is-background li.active {
  background-color: $secondary !important;
}

.el-pagination.is-background li:hover {
  color: $secondary !important;
}
.legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      cursor: pointer;
      font-size: 25px;
      margin-right: 0.5rem;
      &.bi-info-square-fill {
        color: $orange;
      }
      &.bi-question-square-fill {
        color: $primary;
      }
      &.bi-slash-square-fill {
        color: $green;
      }
    }
  }
}

@include bp-down(lg) {
  .legend {
    flex-direction: column;
    button {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
