<template>
  <div class="container">
    <BackRow />
    <div class="row mt-4">
      <h2 class="blue-title mb-4 text-center">
        What are the potential concerns for my company?
      </h2>
    </div>
    <ResultsRow/>
  </div>
</template>

<script>
import ResultsRow from '@/components/specific/ResultsRow';
import BackRow from '@/components/utils/BackRow';

export default {
  name: 'Results',
  components: {
    ResultsRow,
    BackRow,
  },
};
</script>
