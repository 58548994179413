<template>
  <div class="col-12 col-lg-4 mb-2 select-item">
    <p class="mb-2 select-item-label">{{ category.label }}</p>
    <el-select
      v-model="selected"
      filterable
      :placeholder="
        placeholder
          ? `${placeholder} ${category.name}`
          : `Select a ${category.name}`
      "
      @change="handleChange"
      size="large"
    >
      <el-option
        v-for="item in category.items"
        :key="item.id"
        :label="item.content"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'SelectItem',
  props: ['category', 'activated', 'placeholder'],
  emits: ['selected'],
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    activated() {
      if (!this.activated) this.selected = null;
    },
  },
  methods: {
    handleChange() {
      this.$emit('selected', this.category.name, this.selected);
    },
  },
};
</script>
