<template>
  <div class="card mb-4" :class="`card-purple-static`" v-loading="isLoading">
    <h3>
      The use of
      <span class="text-bold">{{ material.content }}</span>
      {{ title }}
    </h3>
    <table class="table">
      <tbody>
        <template v-for="(concern, index) in concerns" :key="index">
          <tr>
            <td>{{ concern.content }}</td>
            <td>
              <div>
                <el-popover
                  v-for="tooltip in tooltips"
                  placement="bottom"
                  :width="200"
                  trigger="hover"
                  :content="getModalSub(tooltip.type)"
                >
                  <template #reference>
                    <i
                      class="bi"
                      :class="tooltip.icon"
                      @click="getModal(tooltip.type, concern)"
                    ></i>
                  </template>
                </el-popover>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <el-dialog
    v-model="showModal"
    :destroy-on-close="true"
    :append-to-body="true"
  >
    <div class="row mb-4">
      <div class="col-12 text-center">
        <h4 class="mb-2">{{ modal.title }}</h4>
        <h5>{{ modal.sub }}</h5>
      </div>
    </div>
    <el-scrollbar max-height="500px" :always="true">
      <template v-if="showChart && showModal && activated">
        <div class="col-12 mb-4">
          <BarChart
            :chartData="data"
            :factsheet="false"
            :highlighted="material"
          />
        </div>
        <div class="col-12 mb-4">
          <ChartLegend :legend="legend" />
        </div>
      </template>
      <div class="row" v-if="showPrevention && activated">
        <TableType :card="card" />
      </div>
      <div class="card card-blue mb-4" v-if="showCauses && activated">
        <p v-for="item in why">{{ item }}</p>
      </div>
      <div class="row" v-if="showReduction && activated">
        <TableType :card="card" />
      </div>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showModal = false">Close</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import BarChart from '@/components/charts/BarChart';
import ChartLegend from '@/components/charts/ChartLegend';
import TableType from '@/components/types/TableType';

import Api from '@/utils/services/Api';

export default {
  name: 'ListItem',
  props: ['branch', 'material', 'activated'],
  emits: ['isActive'],
  components: { BarChart, ChartLegend, TableType },
  data() {
    return {
      concerns: [],
      showChart: false,
      showPrevention: false,
      showCauses: false,
      showReduction: false,
      showModal: false,
      modal: {
        title: '',
        sub: '',
      },
      showChartArr: false,
      data: [],
      charts: [],
      legend: [],
      tooltips: [
        {
          type: 'why',
          icon: 'bi-question-square-fill',
        },
        {
          type: 'materials',
          icon: 'bi-info-square-fill',
        },
        {
          type: 'prevention',
          icon: 'bi-slash-square-fill',
        },
        {
          type: 'reduction',
          icon: 'bi-x-square-fill',
        },
      ],
      card: {},
      why: [],
      isLoading: false
    };
  },
  computed: {
    title() {
      switch (this.branch) {
        case 'access':
          return 'may be affected by the decreased accessibility of the product due to...';
        case 'price':
          return 'may be affected by a substantial change in the product’s price due to...';
        case 'reputation':
          return 'may affect your company’s reputation due to...';
        default:
          return 'may affect your company’s access to a product due to...';
      }
    },
  },
  async created() {
    this.isLoading = true;
    this.concerns = await Api.post(
      'concerns',
      { type: 'list-item' },
      this.material.concerns
    );

    this.isLoading = false;
  },
  methods: {
    async getModal(type, concern) {
      this.showChart =
        this.showPrevention =
        this.showCauses =
        this.showReduction =
          false;

      this.modal = {
        title: concern.content,
        sub: this.getModalSub(type),
      };

      this.activeItem = concern;

      if (type === 'materials') await this.getChartData(concern);
      if (type === 'prevention') await this.getPreventions(concern);
      if (type === 'reduction') await this.getReductions(concern);
      if (type === 'why') await this.getCauses(concern);

      this.$emit('isActive');
      this.showModal = true;
    },
    async getPreventions(concern) {
      const preventions = await Api.post(
        'preventions',
        { type: 'list-item' },
        {
          id: concern.preventions[0],
          branch: this.branch
        }
      );

      this.card = {
        ...preventions,
        options: preventions.options[this.branch]
      }
      this.showPrevention = true;
    },
    async getReductions(concern) {
      const reductions = await Api.post(
        'preventions',
        { type: 'list-item' },
        {
          id: concern.preventions[1],
          branch: this.branch
        }
      );
      this.card = {
        ...reductions,
        options: reductions.options[this.branch]
      }
      this.showReduction = true;
    },
    async getCauses(concern) {
      const causes = await Api.post(
        'causes',
        { type: 'why' },
        {
          branch: this.branch,
          id: concern.id,
        }
      );

      this.why = causes.why;
      this.showCauses = true;
    },
    getModalSub(type) {
      switch (type) {
        case 'materials':
          return 'What other materials does this concern?';
        case 'prevention':
          return 'How can this be prevented?';
        case 'reduction':
          return 'How could potential damage be reduced?';
        case 'why':
          return 'Why is this a problem?';
        default:
          return '';
      }
    },
    async getChartData(concern) {
      const res = await Api.post(
        'charts',
        { type: 'multiple' },
        concern.indicators
      );
      this.data = res.series;
      this.legend = res.legend;
      this.showChart = true;
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  cursor: pointer;
  font-size: 25px;
  &.bi-info-square-fill {
    color: $orange;
    margin-right: 0.5rem;
  }
  &.bi-question-square-fill {
    color: $secondary;
    margin-right: 0.5rem;
  }
  &.bi-slash-square-fill {
    color: $green;
    margin-right: 0.5rem;
  }
  &.bi-x-square-fill {
    color: $primary;
  }
}
.card-intro {
  display: flex;
  align-items: center;
  i {
    margin-right: 1rem;
    &.red {
      color: $red;
    }
    &.green {
      color: $green;
    }
    &.orange {
      color: $orange;
    }
  }
  h3 {
    margin-bottom: 0;
  }
}
.table {
  margin-top: 1rem;
  background-color: white;
  font-size: 18px;
  tr {
    td {
      border: 1px solid $secondary;
    }
  }
}

.vue-apexcharts {
  width: fit-content !important;
}
</style>
